<template>
  <v-card class="overflow-hidden">
    <v-toolbar flat color="white">
      <v-toolbar-title class="font-weight-bold grey--text">
        SEGUIMIENTO
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="black--text font-weight-bold"
        >Estado actual:
        <v-chip outlined :color="eventStateColor(currentState.state.name)">
          {{ currentState.state.name || "SIN ESTADO ASIGNADO" }}
        </v-chip>
      </span>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12" sm="12" md="6" lg="4">
          <span class="green--text">Entidad</span>
          <v-autocomplete
            multiple
            chips
            dense
            solo
            placeholder="Seleccione un valor"
            :items="entities"
            item-text="name"
            v-model="newTracking.entities"
            item-value="id"
            :error-messages="validationErrors.entities"
          >
          </v-autocomplete>
          <span class="green--text">Clase de solicitud</span>
          <v-autocomplete
            v-model="newTracking.request_class_id"
            dense
            solo
            placeholder="Seleccione un valor"
            :items="requestClasses"
            item-text="name"
            item-value="id"
            :error-messages="validationErrors.request_class_id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <span class="green--text">Asunto Radicado</span>
          <v-textarea
            solo
            v-model="newTracking.subject"
            :error-messages="validationErrors.subject"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="8" lg="8" offset-md="4" offset-lg="4">
          <span class="green--text">Solicitud</span>
          <v-textarea
            solo
            v-model="newTracking.description"
            :error-messages="validationErrors.description"
          ></v-textarea>
        </v-col>
      </v-row>
      <div class="text-center mt-2">
        <v-btn small class="mx-2" color="primary" rounded @click="save()" v-if="$can('handle_ddhh')">
          REGISTRAR SEGUIMIENTO
          <v-icon class="ml-1">mdi-content-save</v-icon>
        </v-btn>
      </div>
      <h5>Historial de seguimiento</h5>
      <v-row class="mt-4">
        <v-col cols="12">
          <v-data-table
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              'items-per-page-options': [5,10,15]
            }"
            :headers="headers"
            :items="eventTrackingHistory"
            item-key="id"
          >
            <template v-slot:item.state="{ item }">
              <v-chip outlined :color="eventStateColor(item.state.name)">
                {{ item.state.name || "SIN ESTADO ASIGNADO" }}
              </v-chip>
            </template>
            <template v-slot:item.entity="{ item }">
              <v-chip small v-for="(entity, i) in item.entity" :key="i">
                {{ entity.name }}
              </v-chip>
            </template>
            <template v-slot:item.description="{ item }">
              <p class="text-ellipsis">
                {{ item.description }}
              </p>
            </template>
            <template v-slot:item.response="{ item }">
              <v-icon color="green darken-2" v-if="item.response.length">
                mdi-checkbox-marked-circle
              </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <v-menu left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="$can('handle_ddhh')"
                    @click="$refs.eventEntityResponseComponent.open(item.id)"
                  >
                    <v-list-item-title>
                      <v-icon class="mr-2" small
                        >mdi-message-reply-text-outline</v-icon
                      >
                      Registrar Respuesta
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.response"
                    @click="
                      $refs.eventEntityResponseViewComponent.open(item.response)
                    "
                  >
                    <v-list-item-title>
                      <v-icon class="mr-2" small
                        >mdi-message-reply-text-outline</v-icon
                      >
                      Ver Respuesta
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <event-entity-response
      ref="eventEntityResponseComponent"
      @registerResponse="loadEvent()"
    ></event-entity-response>
    <event-entity-response-view
      ref="eventEntityResponseViewComponent"
    ></event-entity-response-view>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";
import {
  showEvent,
  storeEventEntityTracking,
  listEntities,
  listRequestClases,
    sendMail
} from "@/services/humansRights";
import getEventStateColor from "../../utils/eventStateColors";
import { mapState } from "vuex";
import EventEntityResponse from "./EventEntityResponse.vue";
import EventEntityResponseView from "./EventEntityResponseView.vue";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["eventId"],
  components: { EventEntityResponse, EventEntityResponseView },
  data() {
    return {
      entities: [],
      requestClasses: [],
      headers: [
        { text: "FECHA", value: "date" },
        { text: "ENTIDAD", value: "entity" },
        { text: "CLASE DE SOLICITUD", value: "request_class.name" },
        { text: "DESCRIPCIÓN", value: "description" },
        { text: "RESPUESTA", value: "response" },
        { text: "ACCIONES", value: "action" },
      ],
      eventTrackingHistory: [],
      currentState: {
        state: {},
      },
      newTracking: {
        entities: [],
        request_class_id: "",
        description: "",
        subject: "",
      },
    };
  },
  validations() {
    return {
      newTracking: {
        entities: { required },
        request_class_id: { required },
        description: { required },
        subject: { required },
      },
    };
  },
  async created() {
    const entitiesResponse = await listEntities();
    this.entities = entitiesResponse.data;
    const requestClassesResponse = await listRequestClases();
    this.requestClasses = requestClassesResponse.data;
    await this.loadEvent();
  },
  methods: {
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    async loadEvent() {
      const eventResponse = await showEvent(this.eventId);
      this.eventTrackingHistory = eventResponse.data.event_entity_tracking;
      this.currentState = eventResponse.data.current_state;
    },
    async save() {
      this.$v.newTracking.$touch();
      if (this.$v.newTracking.$invalid) return;
      try {

        this.showLoader();

        const response = await storeEventEntityTracking({
          entities: this.newTracking.entities,
          request_class_id: this.newTracking.request_class_id,
          description: this.newTracking.description,
          subject: this.newTracking.subject,
          event_id: this.eventId,
          user_id: this.user.id,
        });

        await sendMail(response.data.event_id);

        this.hideLoader();

        Swal.fire(
          "Seguimiento guardado correctamente",
          "",
          "success"
        );

        await this.loadEvent();
        this.newTracking = {
          entities: [],
          request_class_id: "",
          description: "",
          subject: "",
        };
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    updateState(state) {
      this.currentState = state;
    },
  },
  computed: {
    ...mapState(["user"]),
    validationErrors() {
      return {
        entities: [
          ...[
            !this.$v.newTracking.entities.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
        ],
        request_class_id: [
          ...[
            !this.$v.newTracking.request_class_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
        ],
        description: [
          ...[
            !this.$v.newTracking.description.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
        ],
        subject: [
          ...[
            !this.$v.newTracking.subject.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
        ],
      };
    },
  },
};
</script>
<style scoped>
.text-ellipsis {
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
