<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon>
            LISTA DE EVENTOS
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon>
            Detalle del evento  <strong class="ml-2"> #{{ newCase.id }} </strong>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>

    <create @updatedEvent="showEvent()" ref="eventForm" mode="edit" />

    <!--ESTADOS DEL CASO-->

    <state-management :eventId="eventId" @updateCurrentState="onUpdateEventState" class="mt-2 mb-2"></state-management>

    <!--CARD SEGUIMIENTO DEL CASO-->
    <event-tracking
      v-if="!newCase.is_female"
      :eventId="eventId"
      class="mt-2 mb-2"
      ref="eventTracking"
    ></event-tracking>
  </v-container>
</template>

<script>
import { showEvent } from "../../services/humansRights";
import StateManagement from "./StateManagement.vue";
import EventTracking from "./EventTracking.vue";
import Swal from "sweetalert2";
import Create from "./case/create.vue";
import {mapState} from "vuex";

export default {
  name: "editEvent",
  data() {
    return {
      headers: [
        {
          text: "NO. IDENTIFICACION",
          align: "start",
          sortable: false,
          value: "documentNumber",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "EDAD", value: "age" },
        { text: "GENERO", value: "genre" },
        { text: "PUEBLO INDÍGENA", value: "sidewalk.descripcion" },
        { text: "RESGUARDO", value: "territory.descripcion" },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
      eventTypes: [],
      territoryTypes: [],
      newCase: {
        id: "",
        event_type_id: "",
        event_subtype_id: "",
        sidewalk_id: "",
        date: "",
        time: "",
        description: "",
        department_id: "",
        zone_id: "",
        municipality_id: "",
        territory_type_id: "",
        territory_id: "",
        contact_information: {
          name: "",
          lastname: "",
          phone_number: "",
          email: "",
        },
        affecteds: [],
        currentState: {},
        requiredData: [],
        evidences: [],
      },
      eventId: "",
      states: [],
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.eventId = id;
    try {
      this.showLoader();
      const eventShowResponse = await showEvent(id);
      const newCase = {
        id: eventShowResponse.data.id,
        disharmony_type_id: eventShowResponse.data.event_type.disharmony,
        event_type_id: eventShowResponse.data.event_type.id,
        event_subtype_id: eventShowResponse.data.event_subtype.id,
        sidewalk_id: eventShowResponse.data.sidewalk?.id || null,
        date: eventShowResponse.data.date,
        time: eventShowResponse.data.time,
        description: eventShowResponse.data.description,
        department_id: eventShowResponse.data.department.id,
        zone_id: eventShowResponse.data.zone?.id || null,
        municipality_id: eventShowResponse.data.municipality.id,
        territory_type_id:
          eventShowResponse.data.territory?.tipo_territorio.id || null,
        territory_id: eventShowResponse.data.territory?.id || null,
        contact_information: {
          name: eventShowResponse.data.contact_information?.name || "",
          lastname: eventShowResponse.data.contact_information?.lastname || "",
          phone_number:
            eventShowResponse.data.contact_information?.phone_number || "",
          email: eventShowResponse.data.contact_information?.email || "",
        },
        affecteds: eventShowResponse.data.affected.map((x) => {
          return {
            affected_id: x.id,
            id: x.people.id,
            name: x.people.name,
            second_name : x.people?.second_name || '',
            lastname: x.people.lastname,
            second_surname : x.people?.second_surname || '',
            documentNumber: x.people.document_number,
            genre: x.people.genre,
            age: x.people.age,
            territory: {
              territorio_id: x.people.territory.id,
              descripcion: x.people.territory.descripcion,
            },
            sidewalk: {
              pueblo_id: x.people.indigenous_group,
              descripcion: this.dataSource.data.Pueblo.find(p => p.pueblo_id === x.people.indigenous_group)?.descripcion || '',
            },
          };
        }),
        currentState: eventShowResponse.data.current_state,
        evidences: eventShowResponse.data.evidences,
        is_female: eventShowResponse.data.is_female,
        event_subtype_values: eventShowResponse.data.event_subtype_values,
      };
      this.$nextTick(() => {
        this.$refs.eventForm.setEventData(newCase);
      });

      if (this.$store.state.syncDataSourceFlag) {
        await this.$store.dispatch("syncDataSource");
        this.$store.commit("SET_SYNC_DATA_SOURCE_FLAG", false);
      }
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  methods: {
    async showEvent() {
      const id = this.$route.params.id;
      this.eventId = id;
      try {
        this.showLoader();
        const eventShowResponse = await showEvent(id);
        const newCase = {
          id: eventShowResponse.data.id,
          disharmony_type_id: eventShowResponse.data.event_type.disharmony,
          event_type_id: eventShowResponse.data.event_type.id,
          event_subtype_id: eventShowResponse.data.event_subtype.id,
          sidewalk_id: eventShowResponse.data.sidewalk?.id || null,
          date: eventShowResponse.data.date,
          time: eventShowResponse.data.time,
          description: eventShowResponse.data.description,
          department_id: eventShowResponse.data.department.id,
          zone_id: eventShowResponse.data.zone?.id || null,
          municipality_id: eventShowResponse.data.municipality.id,
          territory_type_id:
            eventShowResponse.data.territory?.tipo_territorio.id || null,
          territory_id: eventShowResponse.data.territory?.id || null,
          contact_information: {
            name: eventShowResponse.data.contact_information?.name || "",
            lastname:
              eventShowResponse.data.contact_information?.lastname || "",
            phone_number:
              eventShowResponse.data.contact_information?.phone_number || "",
            email: eventShowResponse.data.contact_information?.email || "",
          },
          affecteds: eventShowResponse.data.affected.map((x) => {
            return {
              affected_id: x.id,
              id: x.people.id,
              name: x.people.name,
              second_name : x.people?.second_name || '',
              lastname: x.people.lastname,
              second_surname : x.people?.second_surname || '',
              documentNumber: x.people.document_number,
              genre: x.people.genre,
              age: x.people.age,
              territory: {
                territorio_id: x.people.territory.id,
                descripcion: x.people.territory.descripcion,
              },
              sidewalk: {
                pueblo_id: x.people.indigenous_group,
                descripcion: this.dataSource.data.Pueblo.find(p => p.pueblo_id === x.people.indigenous_group)?.descripcion || '',
              },
            };
          }),
          currentState: eventShowResponse.data.current_state,
          evidences: eventShowResponse.data.evidences,
          is_female: eventShowResponse.data.is_female,
          event_subtype_values: eventShowResponse.data.event_subtype_values,
        };
        this.$nextTick(() => {
          this.$refs.eventForm.setEventData(newCase);
        });
        this.hideLoader();
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    async onUpdateEventState(state){
      await this.showEvent();
      this.$refs.eventTracking.updateState(state);
    }
  },
  components: { StateManagement, EventTracking, Create },
  computed : {
    ...mapState(['dataSource']),
  }
};
</script>

<style scoped></style>
