<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          <v-icon class="mr-2" small>mdi-message-reply-text-outline</v-icon>
          Registrar Respuesta
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="green--text">Entidad</span>
              <v-autocomplete
                dense
                solo
                placeholder="Seleccione un valor"
                :items="entities"
                item-text="name"
                v-model="response.entity_id"
                item-value="id"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="green--text">Fecha de respuesta</span>
              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="response.date"
                    v-bind="attrs"
                    v-on="on"
                    solid
                    dense
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="response.date"
                  @input="datePickerMenu = false"
                  solo
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="green--text">Tipo de respuesta</span>
              <v-radio-group v-model="response.response_type" row>
                <v-radio label="Positiva" value="POSITIVA"></v-radio>
                <v-radio label="Negativa" value="NEGATIVA"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <span class="green--text">Descripción / Observaciones</span>
              <v-textarea
                v-model="response.description"
                row
                solo
                dense
              ></v-textarea>
            </v-col>
            <v-col>
              <file-pond
                allow-multiple="true"
                label-idle="Arrastre aqui sus archivos, o seleccione"
                ref="filePond"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="register"> Registrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond();
import {
  listEntities,
  storeEventEntityTrackingResponse,
} from "@/services/humansRights";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  components: { FilePond },
  data: () => ({
    dialog: false,
    datePickerMenu: "",
    entities: [],
    response: {
      eventEntityTrackingId: "",
      date: "",
      response_type: "",
      description: "",
      evidences: [],
      entity_id: "",
    },
  }),
  methods: {
    async open(id) {
      this.response.eventEntityTrackingId = id;
      this.dialog = true;
      const entitiesResponse = await listEntities();
      this.entities = entitiesResponse.data;
    },
    async register() {
      try {
        this.showLoader();
        this.response.evidences = this.$refs.filePond.getFiles();
        await storeEventEntityTrackingResponse({
          ...this.response,
          user_id: this.user.id,
        });
        this.hideLoader();
        Swal.fire(
          "Respuesta agregada correctamente",
          "",
          "success"
        );
        this.response = {
          eventEntityTrackingId: "",
          date: "",
          response_type: "",
          description: "",
          evidences: [],
        };
        this.$refs.filePond.removeFiles();
        this.$emit("registerResponse");
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
