<template>
  <!-- CARD ESTADO DEL EVENTO -->
  <v-card class="overflow-hidden">
    <v-toolbar flat color="white">
      <v-toolbar-title class="font-weight-bold grey--text">
        ESTADO DEL EVENTO
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="black--text font-weight-bold"
        >Estado actual:
        <v-chip outlined :color="eventStateColor(currentState.state.name)">
          {{ currentState.state.name || "SIN ESTADO ASIGNADO" }}
        </v-chip>
      </span>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12" sm="12" md="6" lg="4">
          <span class="green--text">Estado del evento</span>
          <v-autocomplete
            dense
            solo
            placeholder="Seleccione un valor"
            :error-messages="validationErrors.state_id"
            :items="states"
            item-text="name"
            v-model="newState.state_id"
            item-value="id"
          >
          </v-autocomplete>
          <span class="green--text">Evento asignado a</span>
          <v-autocomplete
            v-model="newState.user_id"
            :error-messages="validationErrors.user_id"
            dense
            solo
            placeholder="Seleccione un valor"
            :items="users"
            item-text="fullName"
            item-value="id"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <span class="green--text">Descripción</span>
          <v-textarea
            solo
            name="input-7-4"
            v-model="newState.description"
            :error-messages="validationErrors.description"
          ></v-textarea>
        </v-col>
      </v-row>
      <div class="text-center mt-2">
        <v-btn small class="mx-2" color="primary" rounded @click="save()" v-if="$can('handle_ddhh')">
          ACTUALIZAR ESTADO <v-icon class="ml-1">mdi-content-save</v-icon>
        </v-btn>
      </div>
      <h5>Historial de estados</h5>
      <v-row class="mt-4">
        <v-col cols="12">
          <v-data-table
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              'items-per-page-options': [5,10,15]

            }"
            :headers="headers"
            :items="eventStateHistory"
            item-key="id"
          >
            <template v-slot:item.state="{ item }">
              <v-chip outlined :color="eventStateColor(item.state.name)">
                {{ item.state.name || "SIN ESTADO ASIGNADO" }}
              </v-chip>
            </template>
            <template v-slot:item.user="{ item }">
              {{
                item.user
                  ? `${item.user.first_name} ${item.user.last_name}`
                  : ""
              }}
            </template>
            <template>
              <v-menu left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-icon class="mr-2" small
                        >mdi-circle-edit-outline</v-icon
                      >
                      Editar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-icon class="mr-2" small>mdi-progress-close</v-icon>
                      Desactivar
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";
import {
  listStates,
  showEvent,
  storeEventStateTracking,
} from "@/services/humansRights";
import { usersList } from "@/services/usersService";
import getEventStateColor from "../../utils/eventStateColors";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["eventId"],
  data() {
    return {
      states: [],
      users: [],
      headers: [
        { text: "FECHA", value: "date" },
        { text: "DESCRIPCIÓN", value: "description" },
        { text: "COORDINADOR", value: "user" },
        { text: "ESTADO", value: "state" },
      ],
      eventStateHistory: [],
      currentState: {
        state: {},
      },
      newState: {
        user_id: "",
        state_id: "",
        description: "",
      },
    };
  },
  validations() {
    return {
      newState: {
        user_id: { required },
        state_id: { required },
        description: { required },
      },
    };
  },
  async created() {
    const statesResponse = await listStates();
    this.states = statesResponse.data;
    const usersResponse = await usersList();
    this.users = usersResponse.data.map((x) => {
      return {
        id: x.id,
        fullName: `${x.first_name} ${x.last_name}`,
      };
    });
    await this.loadEvent();
  },
  methods: {
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    async loadEvent() {
      const eventResponse = await showEvent(this.eventId);
      this.eventStateHistory = eventResponse.data.event_state_tracking;
      this.currentState = eventResponse.data.current_state;
      this.$emit("updateCurrentState", this.currentState);
    },
    async save() {
      this.$v.newState.$touch();
      if (this.$v.newState.$invalid) return;
      try {
        this.showLoader();
        await storeEventStateTracking({
          ...this.newState,
          event_id: this.eventId,
        });
        this.hideLoader();
        Swal.fire(
          "Estado acualizado correctamente",
          "",
          "success"
        );
        this.newState = {
          user_id: "",
          state_id: "",
          description: "",
        };
        await this.loadEvent();
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  computed: {
    validationErrors() {
      return {
        user_id: [
          ...[
            !this.$v.newState.user_id.required ? "Este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        state_id: [
          ...[
            !this.$v.newState.state_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        description: [
          ...[
            !this.$v.newState.description.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
  },
};
</script>
