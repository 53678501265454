<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-text v-if="response.length">
        <v-card v-for="resp in response" elevation="6" :key="resp.id">
          <v-card-title>
            <span class="text-h5">
              <v-icon class="mr-2" small>mdi-message-reply-text-outline</v-icon>
              Respuesta
              <v-chip
                outlined
                :color="resp.type === 'POSITIVA' ? 'green' : 'red'"
              >
                #{{ resp.id }}
              </v-chip>
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <span class="green--text">Entidad</span>
                  <v-text-field
                    readonly
                    v-model="resp.entity.name"
                    solid
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <span class="green--text">Fecha de respuesta</span>
                  <v-text-field readonly v-model="resp.date" solid dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <span class="green--text">Tipo de respuesta</span>
                  <v-radio-group readonly v-model="resp.type" row>
                    <v-radio label="Positiva" value="POSITIVA"></v-radio>
                    <v-radio label="Negativa" value="NEGATIVA"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <span class="green--text">Descripción / Observaciones</span>
                  <v-textarea
                    readonly
                    v-model="resp.description"
                    row
                    solo
                    dense
                  ></v-textarea>
                </v-col>
                <v-col>
                  <p class="green--text">Evidencias</p>
                  <evidence-item
                    class="mx-2 my-2"
                    v-for="(evidence, i) in resp.evidences"
                    :key="`e${i}`"
                    :evidenceItem="evidence"
                  ></evidence-item>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import evidenceItem from "./evidences/evidenceItem.vue";
export default {
  data: () => ({
    dialog: false,
    datePickerMenu: "",
    response: {
      date: "",
      type: "",
      description: "",
      evidences: [],
    },
  }),
  methods: {
    open(response) {
      this.dialog = true;
      this.response = response;
    },
  },
  components: {
    evidenceItem,
  },
};
</script>
